import { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { findIndex } from 'lodash';
import {
  Card,
  Alert,
  Table,
  Stack,
  Paper,
  Box,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Skeleton,
  Grid,
  TextField,

  // Dialog stuff
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Switch,
} from '@mui/material';
import ServicesModal from '../sections/@dashboard/services/Modal';
import CategoriesModal from '../sections/@dashboard/services/CategoriesModal';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import axios from '../utils/Axios';
import { TableSkeleton } from '../components/skeleton';
import {
  TableListHead,
  TableListToolbar,
} from '../sections/@dashboard/services';
import { formatDuration } from '../utils/formatTime';
import palette from 'src/theme/palette';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import { RHFTextField } from 'src/components/hook-form';
import { LoadingButton } from '@mui/lab';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
const data = [
  {
    holidayName: 'test 1',
    startDate: '25-06-2024',
    endDate: '30-06-2024',
    repeat: true
  },
  {
    holidayName: 'test 2',
    startDate: '25-06-2024',
    endDate: '30-06-2024',
    repeat: true
  },
  {
    holidayName: 'test 3',
    startDate: '25-06-2024',
    endDate: '30-06-2024',
    repeat: false
  },
  {
    holidayName: 'test 4',
    startDate: '25-06-2024',
    endDate: '30-06-2024',
    repeat: true
  },
]
const TABLE_HEAD = [
  { id: 'name', label: 'Holiday Name', alignRight: false },
  { id: 'category.name', label: 'Start date', alignRight: false },
  { id: 'duration', label: 'End date', alignRight: false },
  { id: 'days', label: 'Days', alignRight: false },
  { id: 'hidden', label: 'Repeat', alignRight: false },
  { id: '' },
];


function descendingComparator(a, b, orderBy) {
  if (orderBy.includes('.')) {
    const splitMe = orderBy.split('.');
    const first = splitMe[0];
    const second = splitMe[1];

    if (b[first][second] < a[first][second]) {
      return -1;
    }
    if (b[first][second] > a[first][second]) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, category = 'all') {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  array = stabilizedThis.map((el) => el[0]);

  if (query) {
    array = array.filter(
      (service) =>
        service.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  if (category !== 'all') {
    array = array.filter((service) => service.category.name === category);
  }

  return array;
}

export default function Holiday() {
  const { user } = useAuthContext();
  const defaultValues = {
    holidayName: '',
  };
  const [categoryFormErr, setCategoryFormErr] = useState(null);

  const [holidays, setHolidays] = useState([])
  const [isModalAddNew, setIsModalAddNew] = useState(false)
  const [isCheckedForm, setIsCheckedForm] = useState(false)
  const [dates, setDates] = useState([{ name: 'Start Date', value: '' }, { name: 'End Date', value: '' }])
  const [endDate, setEndDate] = useState([])
  const [startDate, setStartDate] = useState([])
  const methods = useForm({ defaultValues });
  const {
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [openPopover, setOpenPopover] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditService, setIsEditService] = useState(false)

  const [selectedServiceRow, setSelectedServiceRow] = useState(null);
  const [selectedCategoryRow, setSelectedCategoryRow] = useState(null);

  const [categoryFormName, setCategoryFormName] = useState('');

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [filterCategories, setFilterCategories] = useState('all');

  const [success, setSuccess] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleOpenModal = (row) => {
    setOpenPopover(null);
    setIsEditService(true);
    setSelectedServiceRow(row);
    setValue('holidayName', row.name)
    setIsCheckedForm(row.isRecurring)
    setDates([{ name: 'Start Date', value: moment(row.startDate) }, { name: 'End Date', value: moment(row.endDate) }])
  };

  const handleCloseModal = () => {
    setOpenModal(false);

    if (openDialog) {
      setOpenDialog(false);
    }

    if (selectedServiceRow != null) {
      setSelectedServiceRow(null);
    }
  };



  const handleCategoryOpenDialog = (selectedCategory, mode) => {
    if (selectedCategory) {
      setSelectedCategoryRow(selectedCategory);
    }

    if (mode === 'edit') {
      setCategoryFormName(selectedCategory.name);
    } else if (mode === 'add') {
      setCategoryFormName('');
    }

    setCategoryDialogMode(mode);
    setOpenCategoryDialog(true);
  };

  const handleCloseCategoryDialog = () => {
    setSelectedCategoryRow(null);
    setCategoryFormName('');
    setCategoryDialogMode(null);
    setCategoryFormErr(null);
    setOpenCategoryDialog(false);
  };

  const handleOpenServiceDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseServiceDialog = () => {
    setServiceFormErr(null);
    setOpenDialog(false);
  };

  const handleOpenMenu = (event, rowId) => {
    setOpenPopover(event.currentTarget);
    setSelectedServiceRow({ _id: rowId });
  };

  const handleCloseMenu = () => {
    setOpenPopover(null);
  };

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterCategories = (event) => {
    setPage(0);
    setFilterCategories(event.target.value);
  };

  const handleDeleteService = async () => {
    try {
      await removeService();
      handleCloseModal();
      setServiceFormErr(null);
    } catch (error) {
      setServiceFormErr(error.messages?.join('') || 'Try again later');
      console.log(error);
    }
  };

  const handleUpdateCategoryForm = (event) => {
    setCategoryFormName(event.target.value);
  };

  const getServicesAndCats = useCallback(async () => {
    try {
      const [servicesResponse, categoriesResponse] = await Promise.all([
        axios.get('/services'),
        axios.get('/holidays'),
      ]);
      setServices(servicesResponse.data.services);
      setHolidays(categoriesResponse.data.categories);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const createService = useCallback(
    async (serviceData) => {
      const response = await axios.post('/services', serviceData);
      setServices([...services, response.data.service]);

      if (Number(serviceData.hidden) !== Number(response.data.service.hidden)) {
        setSuccess({
          bg: 'warning',
          message:
            "You've reached the maximum number of active services for your plan. You can still create a service but it won't be active until you deactivate one of the other services",
        });
      } else {
        setSuccess({
          bg: 'success',
          message: 'Service Created.',
        });
      }

      setTimeout(() => setSuccess(null), 6000);
    },
    [services, setServices]
  );

  const updateService = useCallback(
    async (serviceData) => {
      if (!selectedServiceRow && !selectedServiceRow._id)
        throw new Error('serivice is not selected');
      const serviceId = selectedServiceRow._id;
      const response = await axios.put(`/services/${serviceId}`, serviceData);
      const mutatedServices = [...services];
      const servicIndex = findIndex(mutatedServices, { _id: serviceId });
      mutatedServices.splice(servicIndex, 1, response.data.service);
      setServices(mutatedServices);
    },
    [services, setServices, selectedServiceRow]
  );

  const removeService = useCallback(async () => {
    if (!selectedServiceRow && !selectedServiceRow._id)
      throw new Error('Service is not selected');
    const serviceId = selectedServiceRow._id;
    const response = await axios.delete(`/services/${selectedServiceRow._id}`);
    if (response.status === 200) {
      const mutatedServices = [...services];
      const customerIndex = findIndex(mutatedServices, { _id: serviceId });
      mutatedServices.splice(customerIndex, 1);
      setServices(mutatedServices);
    }
  }, [services, selectedServiceRow, setServices]);

  const updateCategory = useCallback(async (payload) => {
    if (!payload && !payload._id)
      throw new Error('Category is not found');
    const categoryId = payload._id;
    await axios.put(`/holidays/${categoryId}`, payload);
    setIsEditService(false)
    getServicesAndCats();
  }, [categories, setCategories, selectedCategoryRow, categoryFormName]);

  const createCategory = useCallback(async (payload) => {
    const response = await axios.post(`/holidays`, payload);
    setHolidays([...holidays, response.data.holiday]);
    setIsModalAddNew(false)
  }, [holidays, setHolidays, selectedCategoryRow, categoryFormName]);

  const handleUpdateCategory = async (payload) => {
    try {
      await updateCategory(payload);
      handleCloseCategoryDialog();
      setCategoryFormErr(null);
    } catch (error) {
      setCategoryFormErr(error.messages?.join('') || 'Try again later');
    }
  };

  const handleAddCategory = async (payload) => {
    try {
      await createCategory(payload);
      // handleCloseCategoryDialog();
      setCategoryFormErr(null);
    } catch (error) {
      setCategoryFormErr(error.messages?.join('') || 'Try again later');
    }
  };

  const handleDeleteCategory = async (row) => {
    try {
      await removeCategory(row);
      // handleCloseCategoryDialog();
      setCategoryFormErr(null);
    } catch (error) {
      setCategoryFormErr(error.messages?.join('') || 'Try again later');
    }
  };

  const removeCategory = useCallback(async (row) => {
    if (!row && !row._id)
      throw new Error('Category is not selected');
    const categoryId = row._id;
    const response = await axios.delete(`/holidays/${categoryId}`);
    if (response.status === 200) {
      const mutatedCategories = [...holidays];
      const customerIndex = findIndex(mutatedCategories, { _id: categoryId });
      mutatedCategories.splice(customerIndex, 1);
      setHolidays(mutatedCategories);
    }
  }, [holidays, selectedCategoryRow, setHolidays]);

  useEffect(() => {
    getServicesAndCats();
  }, []);

  const filteredServices = applySortFilter(
    services,
    getComparator(order, orderBy),
    filterName,
    filterCategories
  );
  const onUpdate = async (data) => {
    const payload = {
      name: data.holidayName,
      startDate: dates[0].newValue ? dates[0].newValue : dates[0].value,
      endDate: dates[1].newValue ? dates[1].newValue : dates[1].value,
      isRecurring: isCheckedForm,
      _id: selectedServiceRow._id
    }
    handleUpdateCategory(payload)
    // console.log("d",payload);
  }
  const onSubmit = async (data) => {
    const payload = {
      name: data.holidayName,
      startDate: dates[0].value,
      endDate: dates[1].value,
      isRecurring: isCheckedForm
    }
    handleAddCategory(payload)
    // console.log("d",payload);
  }
  return (
    <>
      <Helmet>
        <title>Holiday | {localStorage.getItem("companyName") ?? "FlowBookings"}</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Holiday
          </Typography>
          <Grid>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={(event) => {
                setDates([{ name: 'Start Date', value: '' }, { name: 'End Date', value: '' }])
                setValue('holidayName', '')
                setIsCheckedForm(false)
                setIsModalAddNew(true);
                // handleOpenModal(event, null);
              }}
            >
              Add New
            </Button>
          </Grid>
        </Stack>

        {success != null && (
          <Alert severity={success.bg} color="info" sx={{ mb: 2 }}>
            {success.message}
          </Alert>
        )}
        <Card>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 100 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={services.length}
                  onRequestSort={handleRequestSort}
                />
                {isLoading &&
                  [...Array(5)].map(() => <TableSkeleton shouldShowImage />)}
                {!isLoading && services.length ? (
                  <TableBody>
                    {holidays
                      .map((row, index) => {
                        const {
                          name, startDate, endDate, isRecurring, _id
                        } = row;
                        return (
                          <TableRow hover key={index}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ width: 300 }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >

                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>{moment(startDate).format('D MMMM YYYY')}</TableCell>
                            <TableCell align="left">
                              {moment(endDate).format('D MMMM YYYY')}
                            </TableCell>
                            <TableCell align="left">
                              {moment(endDate).diff(moment(startDate), 'days')} {moment(endDate).diff(moment(startDate), 'days') > 1 ? 'Days' : 'Day'}
                            </TableCell>
                            <TableCell align="left">
                              <Label color={(!isRecurring && 'warning') || 'success'}>
                                {!isRecurring ? 'One Off' : 'Yearly'}
                              </Label>
                            </TableCell>

                            <TableCell align="right">
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(event) => handleOpenMenu(event, _id)}
                              >
                                <Iconify icon="eva:more-vertical-fill" />
                              </IconButton>
                            </TableCell>
                            <Popover
                              open={Boolean(
                                openPopover &&
                                selectedServiceRow &&
                                selectedServiceRow._id === _id
                              )}
                              anchorEl={openPopover}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              PaperProps={{
                                sx: {
                                  p: 1,
                                  width: 140,
                                  '& .MuiMenuItem-root': {
                                    px: 1,
                                    typography: 'body2',
                                    borderRadius: 0.75,
                                  },
                                },
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleOpenModal(row);
                                  setIsEditService(true);
                                }}
                              >
                                <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
                                Edit
                              </MenuItem>

                              <MenuItem
                                sx={{ color: 'error.main' }}
                                onClick={() => {
                                  // handleOpenServiceDialog();
                                  setSelectedServiceRow(row);
                                  handleDeleteCategory(row)
                                }}
                              >
                                <Iconify
                                  icon="eva:trash-2-outline"
                                  sx={{ mr: 2 }}
                                />
                                Delete
                              </MenuItem>
                            </Popover>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  ''
                )}
                {!isLoading && !filteredServices.length && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No Services
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredServices.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Dialog
        minWidth="xl"
        open={isModalAddNew}
        onClose={() => setIsModalAddNew(false)}
      >
        <DialogTitle sx={{ pb: 2 }}>Add Holiday</DialogTitle>

        <DialogContent sx={{ typography: 'body2' }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="subtitle1" sx={{
              opacity: '0.5',
              marginBottom: '4px'
            }} >
              Holiday Name
            </Typography>
            <RHFTextField style={{ marginBottom: '1rem' }} name="holidayName" placeholder='Enter Holiday Name' />
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              //   style={{padding:'1rem 0'}}
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {dates?.map((holiday, index) => (<div key={index} style={{
                  display: 'flex',
                  alignItems: 'start',
                  flexDirection: 'column',
                  marginBottom: '1rem'
                }}>
                  <Typography variant="subtitle1" sx={{
                    opacity: '0.5',
                    marginBottom: '4px'
                  }} >
                    {holiday.name}
                  </Typography>
                  <DatePicker
                    key={index}
                    defaultValue={holiday.value}
                    value={holiday.value}
                    views={['month', 'day']}
                    format='DD-MM-YYYY'
                    onChange={(newDate) => {
                      const newHolidays = [...dates];
                      newHolidays[index].value = newDate;
                      setDates(newHolidays);
                    }}
                  />
                </div>

                ))}
              </LocalizationProvider>
            </Box>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  onChange={(_, val) => {
                    setIsCheckedForm(val);
                  }}
                  checked={isCheckedForm}
                />
              }
              label="Repeat Yearly"
            />

            <DialogActions>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => setIsModalAddNew(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                size="medium"
                variant="contained"
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </FormProvider>
        </DialogContent>
      </Dialog>
      <Dialog
        minWidth="xl"
        open={isEditService}
        onClose={() => setIsEditService(false)}
      >
        <DialogTitle sx={{ pb: 2 }}>Edit Holiday</DialogTitle>

        <DialogContent sx={{ typography: 'body2' }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onUpdate)}>
            <Typography variant="subtitle1" sx={{
              opacity: '0.5',
              marginBottom: '4px'
            }} >
              Holiday Name
            </Typography>
            <RHFTextField style={{ marginBottom: '1rem' }} name="holidayName" placeholder='Enter Holiday Name' />
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              //   style={{padding:'1rem 0'}}
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {dates?.map((holiday, index) => (<div key={index} style={{
                  display: 'flex',
                  alignItems: 'start',
                  flexDirection: 'column',
                  marginBottom: '1rem'
                }}>
                  <Typography variant="subtitle1" sx={{
                    opacity: '0.5',
                    marginBottom: '4px'
                  }} >
                    {holiday.name}
                  </Typography>
                  {holiday.isNew ? (
                    <div
                      onMouseLeave={() => [
                        setDates(dates.map((vel, ind) => {
                          if (ind == index) {
                            return {
                              ...vel,
                              newValue: null,
                              isNew: false
                            }
                          } else {
                            return vel
                          }
                        }))
                      ]}>
                      <DatePicker
                        key={index}
                        value={holiday?.newValue}
                        views={['month', 'day']}
                        format='DD-MM-YYYY'
                        onChange={(newDate) => {
                          const newHolidays = [...dates];
                          newHolidays[index].newValue = newDate;
                          setDates(newHolidays);
                        }}
                      /></div>) : (
                    <TextField
                      size="small"
                      value={moment(holiday.value).format('DD-MM-YYYY')}
                      // disabled
                      onClick={() => {
                        setDates(dates.map((vel, ind) => {
                          if (ind == index) {
                            return {
                              ...vel,
                              newValue: null,
                              isNew: true
                            }
                          } else {
                            return vel
                          }
                        }))
                      }}
                    />
                  )}
                </div>

                ))}
              </LocalizationProvider>
            </Box>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  onChange={(_, val) => {
                    setIsCheckedForm(val);
                  }}
                  checked={isCheckedForm}
                />
              }
              label="Repeat Yearly"
            />

            <DialogActions>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => setIsEditService(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                size="medium"
                variant="contained"
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}
